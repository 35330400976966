import { useEffect, useState } from "react";
import { authentication } from "@microsoft/teams-js";
import { decodeJwt } from "jose";
import { useVisibilityChangeRetry } from "../../hooks/use-visibility-change-retry";
import { logger } from "../../utilities/logger";
import { useExponentialRetry } from "../../hooks/use-exponential-retry";
import { Nullable } from "../../utilities/common-types";

async function getAuthToken() {
  const authToken = await authentication.getAuthToken();
  if (!authToken || authToken === "") {
    throw new Error("Invalid Azure AAD");
  }
  return authToken;
}

export function getTokenExpiry(token: string) {
  const { exp } = decodeJwt(token);
  if (!exp) {
    throw new Error("Invalid expiry");
  }
  return new Date(exp * 1000);
}

export function useAzureActiveDirectoryToken(isInitialized: boolean) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [tokenExpiryDateTime, setTokenExpiryDateTime] = useState<Nullable<Date>>(null);

  const {
    response: token,
    fetchFunction: fetchToken,
    error: fetchTokenError,
  } = useExponentialRetry<string>(getAuthToken, { debugName: "AADToken", startOnInitialize: false });

  useVisibilityChangeRetry(fetchToken, tokenExpiryDateTime, { debugName: "AADToken" });

  useEffect(() => {
    const calculateTokenExpiry = () => {
      if (!token) {
        return;
      }
      let tokenExpiry: Nullable<Date> = null;
      let tokenExpiryError: Nullable<string> = null;
      try {
        tokenExpiry = getTokenExpiry(token);
        logger.debug(`Azure Active Directory token expiry`, { token, tokenExpiry });
      } catch (calculateTokenExpiryError) {
        tokenExpiryError = `Setting Azure Active Directory expiry failed with error ${calculateTokenExpiryError}`;
      }
      setTokenExpiryDateTime(tokenExpiry);
      setError(tokenExpiryError);
    };

    calculateTokenExpiry();
  }, [token]);

  useEffect(() => {
    if (isInitialized && !token && !fetchTokenError) {
      // Start the initial fetch
      void fetchToken();
    }
  }, [fetchToken, fetchTokenError, isInitialized, token]);

  useEffect(() => {
    if (fetchTokenError) {
      setError(`Fetching Azure Active Directory failed with error ${fetchTokenError}`);
    } else {
      setError(null);
    }
  }, [fetchTokenError]);

  return { error, token };
}
