import { Suspense, useCallback, useEffect, useState, lazy } from "react";
import { logger } from "../utilities/logger";
import { useTeamsAppInitialization } from "./hooks/use-teams-app-initialization";
import { useTeamsContext } from "./hooks/use-teams-context";
import { usePlatformApiToken } from "./hooks/use-platform-api-token";
import { useAzureActiveDirectoryToken } from "./hooks/use-azure-active-directory-token";
import { captureMessage } from "@sentry/react";
import { Spinner } from "../components/spinner/spinner";
import { Nullable } from "../utilities/common-types";
import packageJson from "../../package.json";
import AppError from "../components/app-error/app-error";

const FeedReader = lazy(() => import("../components/feed-reader/feed-reader"));

export function App() {
  const [error, setError] = useState<Nullable<string>>(null);
  const { appInitialized, error: teamsAppInitializationError } = useTeamsAppInitialization();
  const { subPageId, appLocale, instanceId, error: teamsAppContextError } = useTeamsContext(appInitialized);
  const { token: azureActiveDirectoryToken, error: azureActiveDirectoryTokenError } =
    useAzureActiveDirectoryToken(appInitialized);
  const {
    platformApiBaseUrl,
    token: platformApiToken,
    error: platformApiTokenError,
  } = usePlatformApiToken(azureActiveDirectoryToken);

  useEffect(() => {
    let appError: Nullable<string> = null;
    if (teamsAppInitializationError) {
      logger.error(teamsAppInitializationError);
      captureMessage("Failed to initialize Teams app", {
        extra: {
          teamsAppInitializationError,
        },
      });
      appError = teamsAppInitializationError;
    }
    if (teamsAppContextError) {
      logger.error(teamsAppContextError);
      captureMessage("Failed to initialize Teams app context", {
        extra: {
          teamsAppContextError,
        },
      });
      appError = teamsAppContextError;
    }
    if (platformApiTokenError) {
      logger.error(platformApiTokenError);
      captureMessage("Failed to authorise with platform API", {
        extra: {
          platformApiError: platformApiTokenError,
        },
      });
      appError = platformApiTokenError;
    }
    if (azureActiveDirectoryTokenError) {
      logger.error(azureActiveDirectoryTokenError);
      captureMessage("Failed to authorise with Azure", {
        extra: {
          azureActiveDirectoryError: azureActiveDirectoryTokenError,
        },
      });
      appError = azureActiveDirectoryTokenError;
    }
    setError(appError);
  }, [azureActiveDirectoryTokenError, platformApiTokenError, teamsAppContextError, teamsAppInitializationError]);

  const fetchToken = useCallback(() => platformApiToken ?? "", [platformApiToken]);

  if (error) {
    return (
      <AppError
        error={error}
        data={{
          appLocale,
          subPageId,
          AppVersion: packageJson.version,
        }}
      />
    );
  }
  if (platformApiBaseUrl && instanceId && appLocale && platformApiToken) {
    return (
      <Suspense fallback={<Spinner />}>
        <FeedReader
          fetchToken={fetchToken}
          instanceId={instanceId}
          hostLanguagePreference={appLocale}
          providedRoute={subPageId}
          apiBaseUrl={platformApiBaseUrl}
        />
      </Suspense>
    );
  }
  return <Spinner />;
}

export default App;
