import Styles from "./app-error.module.css";

export const TEST_ID = {
  ErrorContainer: "App-Error-container",
  SummaryContainer: "App-Error-Summary-container",
  DataContainer: "App-Error-Data-container",
};

export const DEFAULT = {
  error: "An unknown error occurred.",
};

type Props = Readonly<{
  error?: string;
  data?: unknown;
}>;

export function AppError({ error = DEFAULT.error, data }: Props) {
  return (
    <div data-testid={TEST_ID.ErrorContainer} className={Styles.container}>
      <details>
        <summary data-testid={TEST_ID.SummaryContainer}>{error}</summary>
        {data && (
          <div data-testid={TEST_ID.DataContainer}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </details>
    </div>
  );
}

export default AppError;
